<template>
  <van-row>
    <van-col span="24" class="my-order">
      <div class="my-wallet">
        <div span="15" class="title">我的订单</div>
        <div
          span="9"
          class="look-wallet"
          @click="$router.push({ name: 'all-order' })"
        >
          <span>查看全部</span>
          <van-icon name="arrow" size="16" />
        </div>
      </div>
      <div class="order-class-my">
        <div class="order" @click="goTuUrl(1)">
          <img src="~@/assets/my/general-order-background.png" alt="" />
          <div class="font-position">
            <div class="title-text">普通订单</div>
            <div class="all-order-text">全部订单</div>
            <span>{{ userInfo.order_num_p }}</span>
          </div>
        </div>
        <div class="order beans-order" @click="goTuUrl(2)">
          <img src="~@/assets/my/charity-bean-order-background.png" alt="" />
          <div class="font-position">
            <div class="title-text">公益豆订单</div>
            <div class="all-order-text">全部订单</div>
            <span>{{ userInfo.order_num_g }}</span>
          </div>
        </div>
      </div>
      <van-row>
        <van-col class="returns-order" @click="goTuUrl(3)">
          <img src="~@/assets/my/returns-the-background.png" alt="" />
          <div class="font-position">
            <div class="title-text">退款/售后</div>
            <div class="all-order-text">全部订单</div>
            <span>{{ userInfo.return_num }}</span>
          </div>
        </van-col>
      </van-row>
    </van-col>
  </van-row>
</template>

<script>
export default {
  name: "my-order",
  props: ["userInfo"],
  data() {
    return {};
  },
  methods: {
    goTuUrl(type) {
      switch (type) {
        case 1:
          this.$router.push({ name: "all-order", query: { orderType: 1 } });
          break;
        case 2:
          this.$router.push({ name: "all-order", query: { orderType: 3 } });
          break;
        case 3:
          this.$router.push({ name: "sell-behind", query: { orderType: 1 } });
          break;
        default:
      }
    },
  },
};
</script>

<style lang="less" scoped>
.my-order {
  background-color: #fff;
  padding: 14px 13px;
  border-radius: 15px;
  margin-top: 12px;
}
.my-wallet {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 17px;
  .title {
    font-weight: 500;
  }
  .look-wallet {
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 14px;
    font-weight: normal;
    color: #bbbbbb;
  }
}
.order-class-my {
  display: flex;
  justify-content: space-between;
  margin-top: 13px;
  .order {
    position: relative;
    width: 49%;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}
.font-position {
  position: absolute;
  top: 14px;
  left: 16px;
  .title-text {
    font-size: 16px;
  }
  .all-order-text {
    margin: 8px 0;
    font-size: 14px;
    color: #6f6f6f;
  }
  span {
    font-size: 24px;
    color: #000;
  }
}
.order-one-row {
  display: flex;
  justify-content: space-between !important;
  width: 100%;
  margin-top: 12px;
}
.order-one-row img {
  display: block;
  width: 100%;
}
.general-order {
  position: relative;
  width: 175px;
  padding: 0 !important;
  flex: none;
}
.charity-bean-order {
  position: relative;
  width: 175px;
  padding: 0 !important;
  flex: none;
}
.returns-order {
  margin-top: 13px;
  position: relative;
  img {
    display: block;
    width: 100%;
  }
}
</style>
