<template>
  <div class="evolution-earnings">
    <div class="my-progress-earnings">更多功能</div>
    <van-grid :border="false">
      <van-grid-item   v-if="false" :icon="sign" text="签到有礼" @click="goToUrl('sign')" />
      <van-grid-item
        v-if="false"
        :icon="discounts"
        :badge="userInfo.coupon_num > 0 ? userInfo.coupon_num : ''"
        text="优惠券"
        @click="goToUrl('discounts')"
      />
      <van-grid-item :icon="orderImg" text="扫码订单" @click="goToUrl(1)" />
      <van-grid-item :icon="cloudImg" text="云相册" @click="goToUrl('cloud')" />
      <van-grid-item :icon="usedImg" text="我的闲置" @click="goToUrl('used')" />
      <van-grid-item
        :icon="codeImg"
        text="收款码"
        @click="goToUrl('twoCode')"
        v-if="userInfo.level_id === 5"
      />
      <van-grid-item :icon="evaluatedImg" text="待评价" @click="goToUrl(2)" />
      <van-grid-item :icon="publicdImg" text="公益豆" @click="goToUrl(3)" />
      <van-grid-item :icon="addressImg" text="地址管理" @click="goToUrl(4)" />
      <van-grid-item
        :icon="shopImg"
        text="我要开店"
        v-if="false"
        @click="goToUrl(5)"
      />
      <van-grid-item
        :icon="replenishImg"
        text="进货金"
        v-if="userInfo.level_id === 5"
        @click="goToUrl(6)"
      />
      <van-grid-item
        :icon="messageImg"
        text="我的消息"
        :badge="newsNum > 0 ? newsNum : ''"
        @click="goToUrl(7)"
      />
      <van-grid-item :icon="systemImg" text="系统通知" @click="goToUrl(8)" />
      <van-grid-item :icon="serviceImg" text="客服" @click="goToUrl(10)" />
      <van-grid-item :icon="aboutImg" text="关于我们" @click="goToUrl(9)" />
    </van-grid>
    <GatherMoney :isShare="isShare" @colseGather="colseGather"></GatherMoney>
    <div class="mark" v-if="show">
      <input type="text" v-model="inputVal" />
      <button @click="onClick">确定</button>
      <button @click="cancel">取消</button>
    </div>
  </div>
</template>

<script>
import GatherMoney from "@/components/gather-money/gather-money";
import { getUserInfo } from "@/api/index.js";
import { getMyDiscount } from "@/api/discounts.js";
import { getWaitRead } from "@/api/im";
export default {
  name: "more-option",
  props: ["userInfo"],
  components: {
    GatherMoney,
  },
  data() {
    return {
      isShare: false,
      levelId: "",
      orderImg: require("@/assets/my/my-order-icon.png"),
      evaluatedImg: require("@/assets/my/my-icon-evaluated.png"),
      publicdImg: require("@/assets/my/my-good-beans.png"),
      addressImg: require("@/assets/my/my-address-management.png"),
      shopImg: require("@/assets/my/i_need_a_shop_icon@2x.png"),
      replenishImg: require("@/assets/my/purchase_price_icon@2x.png"),
      messageImg: require("@/assets/my/my_message_icon@2x.png"),
      systemImg: require("@/assets/my/my-system-notification.png"),
      aboutImg: require("@/assets/my/about_us@2x.png"),
      serviceImg: require("@/assets/my/my-about-us.png"),
      cloudImg: require("@/assets/my/icon-for-photo.png"),
      usedImg: require("@/assets/my/icon-for-used.png"),
      codeImg: require("@/assets/my/payment_code@2x.png"),
      discounts: require("@/assets/my/discounts-icon@2x.png"),
      sign: require("@/assets/my/icon@3x.png"),
      newsNum: 0, //消息提示
      showPopup: false,
      inputVal: "",
      show: false,
    };
  },
  mounted() {
    this.getWaitRead();
  },
  methods: {
    goToUrl(type) {
      //页面跳转
      switch (type) {
        case 1:
          //订单详情
          this.$router.push({ name: "scan-code" });
          break;
        case 2:
          //店铺详情
          this.$router.push({ name: "my-evaluate", query: { active: 2 } });
          break;
        case 3:
          this.$router.push({ name: "my-beans" });
          break;
        case 4:
          this.$router.push({ name: "address-list", query: { isClose: true } });
          break;
        case 5:
          this.$router.push({ name: "register-info" });
          break;
        case 6:
          this.$router.push({ path: "/stock-gold" });
          break;
        case 7:
          this.$router.push({ name: "chitchat" });
          break;
        case 8:
          this.$router.push({ name: "system-inform" });
          break;
        case 9:
          this.$router.push({ name: "about-us" });
          break;
        case 10:
          this.$router.push({ name: "information", query: { groupType: 3 } });
          break;
        case "cloud":
          this.$router.push({ name: "cloud-photo" });
          break;
        case "used":
          this.$router.push({ name: "my-used" });
          break;
        case "twoCode":
          this.isShare = true;
          break;
        case "discounts":
          this.$router.push({ name: "my-discounts" });
          break;
        case "sign":
          // this.show = true;
          this.$router.push({ name: "sign", query: { date: '' } });
          break;
        default:
      }
    },
    onClick() {
      this.$router.push({ name: "sign", query: { date: this.inputVal } });
    },
    cancel() {
      this.show = false;
    },
    colseGather() {
      this.isShare = false;
    },

    async getUser() {
      // const res = await getUserInfo();
      // this.levelId = this.userInfo.level_id;
      // this.levelId = res.data.level_id;
    },
    async getWaitRead() {
      let res = await getWaitRead();
      if (res.code * 1 === 1) {
        this.newsNum = res.data.wait_read || 0;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.evolution-earnings {
  padding: 14px 13px;
  background-color: #fff;
  border-radius: 15px;
  margin-top: 13px;
}
/deep/.van-icon__image {
  width: 31px;
  height: 31px;
}
.my-progress-earnings {
  margin: 0;
  font-size: 17px;
  font-weight: 500;
}
/deep/ .van-grid-item__content .van-grid-item__text {
  font-size: 14px;
}
/deep/.van-info {
  top: 5px;
  right: 5px;
}
.mark {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(0, 0, 0, 0.5);
}
</style>
