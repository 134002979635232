<template>
  <div class="evolution-earnings">
    <div class="shop-order-box">
      <div class="my-progress-earnings">商品订单</div>
      <div span="9" class="look-wallet">
        <span @click="goToOrderList(0)">全部订单</span>
        <van-icon name="arrow" size="16" />
      </div>
    </div>

    <van-grid :border="false">
      <van-grid-item
        :icon="paymentImg"
        text="待付款"
        :badge="badgeData.no_pay === 0 ? '' : badgeData.no_pay"
        @click="goToOrderList(1)"
      />
      <van-grid-item
        :icon="shippedImg"
        text="待发货"
        :badge="badgeData.no_deliver === 0 ? '' : badgeData.no_deliver"
        @click="goToOrderList(2)"
      />
      <van-grid-item
        :icon="receiveImg"
        text="待收货"
        :badge="badgeData.no_affirm === 0 ? '' : badgeData.no_affirm"
        @click="goToOrderList(3)"
      />
      <van-grid-item
        :icon="refundSaleImg"
        text="退款/售后"
        :badge="badgeData.return_num === 0 ? '' : badgeData.return_num"
        @click="goToOrderList(4)"
      />
    </van-grid>
  </div>
</template>

<script>
import { getOrderNum } from "@/api/order";
export default {
  name: "shop-order",
  props: ["orderType"],
  data() {
    return {
      paymentImg: require("@/assets/my/icon-for-payment.png"),
      shippedImg: require("@/assets/my/refund-sale-icon.png"),
      receiveImg: require("@/assets/my/to-receive-icon.png"),
      refundSaleImg: require("@/assets/my/refund-sale.png"),
      orderNum: {
        no_affirm: 0,
        no_deliver: 0,
        no_pay: 20,
        return_num: 0,
      },
      badgeData: {},
    };
  },
  mounted() {
    this.getOrderNum();
  },
  methods: {
    goToOrderList(orderStatus) {
      if (orderStatus < 4) {
        this.$router.push({
          name: "purchases-order",
          query: { orderType: 2, orderStatus: orderStatus },
        });
      } else {
        this.$router.push({ name: "sell-behind", query: { orderType: 2 } });
      }
    },
    async getOrderNum() {
      const ret = await getOrderNum({
        order_type: 2,
      });
      this.badgeData = ret.data;
      if (ret.code * 1 == 1) {
        this.orderNum = ret.data;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.evolution-earnings {
  padding: 14px 10px 0px 10px;
  background-color: #fff;
  border-radius: 15px;
  margin-top: 13px;
}
.my-progress-earnings {
  margin: 0;
  font-size: 17px;
  font-weight: 500;
}
.shop-order-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.look-wallet {
  display: flex;
  justify-content: end;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  color: #bbbbbb;
}
/deep/ .van-grid-item__content .van-grid-item__text {
  font-size: 14px;
  color: #6f6f6f;
  font-weight: 500;
}
/deep/.van-info {
  top: 2px;
  right: 2px;
  line-height: 1;
}
</style>
