<template>
  <div>
    <van-overlay :show="showSharePage" @click="show = false" lock-scroll>
      <div class="wrapper" @click.stop>
        <div class="block">
          <van-icon
            name="close"
            class="shutDown-icon"
            size="30"
            @click="shutDown"
          />
          <div class="share-img-box">
            <img v-show="Img" :src="Img" id="imageWrapper" class="block" />
          </div>
          <!-- <div class="user-info">
            <div class="head-portrait">
              <img :src="shareInfo.head_img || headImgUrl" class="head-img" />
            </div>
            <div class="info">
              <div class="user-name">{{ shareInfo.nickname }}</div>
              <div class="user-id">推荐码:{{ shareInfo.invite_code }}</div>
            </div>
          </div> -->
          <!-- <div class="two-code">
            <img :src="shareInfo.code_img" />
          </div> -->
          <!-- 分享的路径 -->
          <div class="share-path">
            <div class="wechat-friends share-layout" @click="shareFriend">
              <img src="~@/assets/common/share-save-album.png" alt="" />
              <span>分享好友</span>
            </div>
            <div class="save-photo share-layout" @click="preserve">
              <img
                src="~@/assets/common/share-wechat-distribution.png"
                alt=""
              />
              <span>保存相册</span>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
    <div class="popup-tier" :class="{ none: isShow }">
      <div class="got-it" @click="understand">
        <img src="~@/assets/my/cancel_button@2x.png" alt="" />
      </div>
      <div class="hint">
        <img src="~@/assets/my/sharetips_img@2x.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import tools from "@/common/js/tools.js";
import { Dialog } from "vant";
import html2canvas from "html2canvas";
import { getShareInfo } from "@/api/member";
export default {
  name: "",
  props: {
    headImgUrl: require("@/assets/common/personal_centerhead_portrait.png"),
    showSharePage: {
      default: false,
    },
  },
  data() {
    return {
      show: true,
      shareInfo: {
        code_img: "",
        head_img: "",
        id: 0,
        invite_code: "",
        nickname: "",
        share_img: "",
      },
      Img: "",
      isShow: false,
      isWx: false,
    };
  },
  mounted() {
    this.isWx = tools.isWx();
    if (this.isWx) {
      tools.wxInitialize([
        "onMenuShareAppMessage",
        "updateAppMessageShareData",
        "updateTimelineShareData",
      ]);
    }
    if (this.showSharePage) {
      this.getShareInfo();
    }
  },
  methods: {
    understand() {
      this.isShow = false;
    },
    shareFriend() {
      console.log(tools.isWx());
      if (tools.isWx()) {
        this.isShow = true;
      } else {
        Dialog.alert({
          title: "提示",
          message: "请到微信分享给你最爱的朋友吧~~",
          theme: "round-button",
        }).then(() => {
          // on close
        });
      }
    },
    preserve() {
      if (tools.isWx()) {
        Dialog.alert({
          title: "提示",
          message: "长按图片保存到相册~~",
          theme: "round-button",
        }).then(() => {
          // on close
        });
      } else {
        Dialog.alert({
          title: "提示",
          message: "请到微信分享给你最爱的朋友吧~~",
          theme: "round-button",
        }).then(() => {
          // on close
        });
      }
    },
    setWxShare() {
      if (!this.isWx) {
        return false;
      }
      let _that = this;
      wx.ready(function () {
        // 需在用户可能点击分享按钮前就先调用
        let myUrl = window.location.href;
        if (myUrl.indexOf("?") <0) {
          myUrl = myUrl+'?';
        }
        if (myUrl.indexOf("m_id") !== -1) {
          myUrl = myUrl.substr(0, myUrl.indexOf("m_id"));
        }
        myUrl = myUrl + "&invite_code=" + _that.shareInfo.invite_code;
        console.log(myUrl);
        wx.updateAppMessageShareData({
          title: _that.shareInfo.nickname, // 分享标题
          desc: "分享商品啦", // 分享描述
          link: myUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: _that.shareInfo.code_img, // 分享图标
          success: function () {
            // 设置成功
          },
        });
        wx.updateTimelineShareData({
          title: _that.shareInfo.nickname, // 分享标题
          link: myUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: _that.shareInfo.code_img, // 分享图标
          success: function () {
            // 设置成功
          },
        });
      });
    },
    html2canvas(imgsrc, name) {
      //下载图片地址和图片名
      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = name || "photo"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgsrc;
    },
    downs() {
      this.html2canvas(this.Img, "pic");
    },
    // 点击保存
    // toSave() {
    //   html2canvas(document.getElementById("imageWrapper")).then((canvas) => {
    //     let saveUrl = canvas.toDataURL("image/png");
    //     let aLink = document.createElement("a");
    //     let blob = this.base64ToBlob(saveUrl);
    //     let evt = document.createEvent("HTMLEvents");
    //     evt.initEvent("click", true, true);
    //     aLink.download = "img.jpg";
    //     aLink.href = URL.createObjectURL(blob);
    //     aLink.click();
    //     console.log(aLink.href);
    //   });
    // },
    // // 这里把图片转base64
    // base64ToBlob(code) {
    //   let parts = code.split(";base64,");
    //   let contentType = parts[0].split(":")[1];
    //   let raw = window.atob(parts[1]);
    //   let rawLength = raw.length;
    //   let uInt8Array = new Uint8Array(rawLength);
    //   for (let i = 0; i < rawLength; ++i) {
    //     uInt8Array[i] = raw.charCodeAt(i);
    //   }
    //   return new Blob([uInt8Array], { type: contentType });
    // },
    shutDown() {
      this.$emit("setShareCode", false);
    },
    async getShareInfo() {
      const ret = await getShareInfo();
      if (ret.code * 1 === 1) {
        this.shareInfo = ret.data;
        this.Img = ret.data.share_img;
        this.setWxShare();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.van-overlay {
  z-index: 2000;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.popup-tier {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.8);
  .got-it {
    position: absolute;
    bottom: 226px;
    right: 96px;
    width: 186px;
    img {
      display: block;
      width: 100%;
    }
  }
  .hint {
    position: absolute;
    right: 0;
    top: 0;
    width: 270px;
    img {
      display: block;
      width: 100%;
    }
  }
}
.none {
  display: block;
}
.block {
  position: relative;
  width: 336px;
  height: 450px;
  .share-img-box {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    overflow: hidden;
  }
  .shutDown-icon {
    position: absolute;
    top: -42px;
    left: 50%;
    transform: translateX(-50%);
    color: #cccccc;
  }

  .user-info {
    position: absolute;
    top: 102px;
    left: 28px;
    display: flex;
    align-items: center;

    .head-portrait {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 58px;
      height: 58px;
      margin-right: 10px;
      border-radius: 50%;
      overflow: hidden;
      box-shadow: 0 2px 4px #f5f6f7;
      .head-img {
        width: 100%;
        display: block;
      }
    }

    .info {
      display: flex;
      flex-direction: column;

      .user-name {
        font-weight: 600;
        font-size: 16px;
      }

      .user-id {
        padding-top: 6px;
        font-size: 12px;
        color: #6f6f6f;
      }
    }
  }

  .two-code {
    position: absolute;
    bottom: 74px;
    left: 50%;
    transform: translateX(-50%);
    width: 150px;
    height: 150px;
    img {
      width: 100%;
      display: block;
    }
  }
}

.share-path {
  position: absolute;
  bottom: -86px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-around;
  width: 336px;

  .share-layout {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 40px;
      margin-bottom: 10px;
    }

    span {
      color: #fff;
      font-size: 14px;
    }
  }
}
</style>
