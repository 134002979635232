<template>
  <van-row class="layout-user">
    <van-col span="24">
      <!-- 第一行 -->
      <van-row class="info">
        <div class="info-box" @click="goToUrl(1)">
          <van-col>
            <div class="header-img">
              <img :src="userInfo.head_img || headImgUrl" />
            </div>
          </van-col>
          <van-col class="grade">
            <div class="member-info">
              <div class="username-info">{{ userInfo.nickname }}</div>
              <img
                src="~@/assets/my/grade-1.png"
                v-if="userInfo.level_id === 1"
                class="DengJi"
              />
              <img
                src="~@/assets/my/grade-2.png"
                v-else-if="userInfo.level_id === 2"
                class="DengJi"
              />
              <img
                src="~@/assets/my/grade-3.png"
                v-else-if="userInfo.level_id === 3"
                class="DengJi"
              />
              <img
                src="~@/assets/my/grade-4.png"
                v-else-if="userInfo.level_id === 4"
                class="DengJi"
              />
              <img
                src="~@/assets/my/grade-5.png"
                v-else-if="userInfo.level_id === 5"
                class="DengJi"
              />
            </div>
            <div
              v-if="
                userInfo.recommend_name !== '' &&
                userInfo.level_id > 1 &&
                userInfo.level_id <= 5
              "
              class="recommend-info"
            >
             {{ userInfo.recommend_name }}
            </div>
            <div class="address-info" v-if="userInfo.level_id < 5">
              {{ shopCity(userInfo.shop_city) }}
            </div>
          </van-col>
        </div>
        <!-- <van-col>
          <img
            src="~@/assets/my/my-qr-code.png"
            class="two-code"
            @click="openShareCode"
          />
        </van-col> -->
      </van-row>
      <!-- 第二行 -->
      <van-row>
        <van-col class="merchandise-info" span="24">
          <van-row class="rows-info" @click="goToUrl(2)">
            <van-col class="font-color">{{
              userInfo.shop_collect_num || 0
            }}</van-col>
            <van-col>店铺收藏</van-col>
          </van-row>
          <div class="partition"></div>
          <van-row class="rows-info" @click="goToUrl(3)">
            <van-col class="font-color">{{ userInfo.goods_num || 0 }}</van-col>
            <van-col>商品收藏</van-col>
          </van-row>
          <div class="partition"></div>
          <van-row class="rows-info" @click="goToUrl(5)">
            <van-col class="font-color">
              {{ userInfo.browse_num || 0 }}
            </van-col>
            <van-col>浏览足迹</van-col>
          </van-row>
          <div class="partition"></div>
          <van-row class="rows-info" @click="goToUrl(4)">
            <van-col class="font-color">
              {{ userInfo.comment_num || 0 }}
            </van-col>
            <van-col>我的评价</van-col>
          </van-row>
        </van-col>
      </van-row>
    </van-col>
    <ShareCode
      :showSharePage="showSharePage"
      @setShareCode="setShareCode"
      v-if="showSharePage"
    ></ShareCode>
  </van-row>
</template>

<script>
import ShareCode from "./share-code";
export default {
  name: "user-info",
  components: {
    ShareCode,
  },
  props: {
    userInfo: {
      default: {
        level_id: 8,
      },
    },
    shopInfo: {
      default: {
        recom_name: "",
      },
    },
  },
  data() {
    return {
      showSharePage: false,
      headImgUrl: require("@/assets/common/personal_centerhead_portrait.png"),
    };
  },
  methods: {
    openShareCode() {
      this.showSharePage = true;
    },
    setShareCode() {
      this.showSharePage = false;
    },
    shopCity(str) {
      let s = "";
      if (str.length >= 13) {
        s = str.slice(str.length - 13);
        s = "..." + s;
      } else {
        s = str;
      }
      return s;
    },
    goToUrl(type) {
      switch (type) {
        case 1:
          this.$router.push({
            path: "/alter-user-info",
            query: {
              nickname: this.userInfo.nickname,
              phone: this.userInfo.phone,
              sex: this.userInfo.sex,
              head_img: this.userInfo.head_img,
              phone_num: this.userInfo.phone_num,
            },
          });
          break;
        case 2:
          this.$router.push({ name: "shop-collection" });
          break;
        case 3:
          this.$router.push({ name: "my-collection" });
          break;
        case 4:
          this.$router.push({ name: "my-evaluate" });
          break;
        case 5:
          this.$router.push({ name: "browse-record" });
          break;
        default:
      }
    },
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 120px;
  height: 120px;
  background-color: #fff;
}
.layout-user {
  border-radius: 15px;
  margin-top: 20px;
  background-color: #fff;
  padding: 12px;
}
.header-img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: start;
  align-items: center;
  box-shadow: 0 2px 4px #f5f6f7;
  img {
    width: 100%;
    display: block;
  }
}

.username-info {
  font-size: 18px;
  font-weight: 400;
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.info {
  position: relative;
  display: flex;
  .info-box {
    width: 87%;
  }
}

.two-code {
  position: absolute;
  right: 0;
  top: 12px;
  width: 31px;
}

.DengJi {
  margin-left: 10px;
  width: 69px;
  height: 26px;
  border-radius: 26px;
}

.merchandise-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  padding-top: 10px;
}

.font-color {
  color: #000;
  font-size: 23px;
}

.rows-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  color: #6f6f6f;
  font-size: 16px;
}

.partition {
  height: 16px;
  width: 1px;
  background-color: #aaaaaa;
  opacity: 0.4;
}
.grade {
  margin: 12px 0 0 6px;
}
.member-info {
  display: flex;
  align-items: center;
}
.recommend-info {
  font-size: 16px;
  color: #bbbbbb;
  width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.address-info {
  color: #bbbbbb;
  font-size: 16px;
  max-width: 224px;
}
</style>
