<template>
  <div class="evolution-earnings">
    <div class="my-progress-earnings">发展收益</div>
    <div class="row">
      <div class="col" @click="goToUrl(1, 0)">
        <img
          src="~@/assets/my/team-management-background.png"
          class="progress-img-width"
        />
        <div class="font-position">
          <div>分销团队</div>
          <span>{{ userInfo.team_num || 0 }}人</span>
        </div>
      </div>
      <div class="col" @click="goToUrl(2, 0)">
        <img
          src="~@/assets/my/distribution-revenue-icon.png"
          class="progress-img-width"
        />
        <div class="font-position">
          <div>分销收益</div>
          <span> 近7日收益￥{{ userInfo.sales_earnings || "0.00" }} </span>
        </div>
      </div>
    </div>
    <div
      class="row"
      v-if="userInfo.level_id * 1 <= 5 && userInfo.shop_status * 1 == 1"
    >
      <div class="col" @click="goToUrl(3, 0)">
        <img
          src="~@/assets/my/team-background.png"
          class="progress-img-width"
        />
        <div class="font-position">
          <div>团队管理</div>
          <span>{{ userInfo.shop_num || 0 }}人</span>
        </div>
      </div>
      <div class="col" @click="goToUrl(4, 0)">
        <img
          src="~@/assets/my/my-revenue-picture-img.png"
          class="progress-img-width"
        />
        <div class="font-position">
          <div>我的收益</div>
          <span> 近7日收益￥{{ userInfo.my_earnings || "0.00" }} </span>
        </div>
      </div>
    </div>
    <van-row v-if="userInfo.level_id * 1 < 5 && userInfo.shop_status * 1 == 1">
      <van-col span="24" class="set-img-position" @click="joinTheTeam">
        <img
          src="~@/assets/my/invite-to-add-pictures.png"
          class="progress-img-width"
        />
        <img src="~@/assets/my/check-the-button.png" class="check-the-button" />
        <div class="font-position">
          <div>{{ promotion }}</div>
          <span class="click-look">点击查看</span>
        </div>
      </van-col>
    </van-row>
    <ShareCode
      :showSharePage="showSharePage"
      @setShareCode="setShareCode"
      v-if="showSharePage"
    ></ShareCode>
  </div>
</template>

<script>
import ShareCode from "./share-code";
export default {
  name: "evolution-earnings",
  components: {
    ShareCode,
  },
  props: {
    userInfo: {
      default: {},
    },
  },
  data() {
    return {
      showSharePage: false,
    };
  },
  computed: {
    promotion() {
      let str = "邀请推荐";
      if (this.userInfo.level_id === 1) {
        str = "推广市级代理二维码";
        return str;
      } else if (this.userInfo.level_id === 2) {
        str = "推广县级代理二维码";
        return str;
      } else if (this.userInfo.level_id === 3) {
        str = "推广分销商二维码";
        return str;
      } else if (this.userInfo.level_id === 4) {
        str = "推广实体店二维码";
      }
      return str;
    },
  },
  methods: {
    joinTheTeam() {
      this.showSharePage = true;
    },
    setShareCode() {
      this.showSharePage = false;
    },
    goToUrl(type, data) {
      switch (type * 1) {
        case 1:
          this.$router.push({ name: "divide-sell" });
          break;
        case 2:
          this.$router.push({
            name: "divide-earnings",
            query: { searchStr: data },
          });
          break;
        case 3:
          this.$router.push({
            name: "group-supervise",
            query: { searchStr: data },
          });
          break;
        case 4:
          this.$router.push({
            name: "shop-earnings",
            query: { searchStr: data },
          });
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.evolution-earnings {
  padding: 14px 13px;
  background-color: #fff;
  border-radius: 15px;
  margin-top: 13px;
}
.my-progress-earnings {
  margin: 0;
  font-size: 17px;
  font-weight: 500;
}
.row {
  display: flex;
  justify-content: space-between;
  .col {
    position: relative;
    width: 49%;
    margin-top: 13px;
    img {
      display: block;
    }
  }
}
/* 发展管理的相对定位 */
.set-img-position {
  margin-top: 12px;
  position: relative;
  font-size: 0;
}
.progress-img-width {
  width: 100%;
}
.check-the-button {
  position: absolute;
  top: 30px;
  left: 160px;
  width: 28px;
}
/* 文字位置 */
.font-position {
  position: absolute;
  top: 14px;
  left: 16px;
}

.font-position div {
  padding-bottom: 8px;
  font-size: 16px;
  color: #000000;
}
.font-position span {
  font-size: 14px;
  color: #6f6f6f;
}
.font-position .click-look {
  color: #6f6f6f;
}
</style>
