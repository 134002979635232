<template>
  <div>
    <van-popup v-model="showSharePage">
      <div class="block">
        <div class="code-img-box">
          <img :src="shareInfo.shop_share_img" class="code-img" />
        </div>
        <van-icon name="close" class="close-icon" size="30" @click="shutDown" />
        <div class="share-path">
          <div class="wechat-friends share-layout" @click="shareFriend">
            <img src="~@/assets/common/share-save-album.png" alt="" />
            <span>发送好友</span>
          </div>
          <div class="save-photo share-layout" @click="preserve">
            <img src="~@/assets/common/share-wechat-distribution.png" />
            <span>保存相册</span>
          </div>
        </div>
      </div>
    </van-popup>
    <!-- <van-overlay :show="showSharePage" @click="show = false" lock-scroll>
      <div class="wrapper" @click.stop>
        <div class="block">
          <van-icon
            name="close"
            class="shutDown-icon"
            size="30"
            @click="shutDown"
          />
          <div class="user-info">
            <div class="head-portrait">
              <img :src="shareInfo.shop_logo" class="head-img" />
            </div>
            <div class="info">
              <div class="user-name">{{ shareInfo.shop_name }}</div>
              <div class="user-id">推荐码:{{ shareInfo.shop_code }}</div>
            </div>
          </div>
          <div class="two-code">
            <img :src="shareInfo.code_img" />
          </div>
          <div class="share-path">
            <div class="wechat-friends share-layout" @click="shareFriend">
              <img src="~@/assets/common/share-save-album.png" alt="" />
              <span>发送好友</span>
            </div>
            <div class="save-photo share-layout" @click="preserve">
              <img src="~@/assets/common/share-wechat-distribution.png" />
              <span>保存相册</span>
            </div>
          </div>
        </div>
      </div>
    </van-overlay> -->
    <div class="popup-tier" :class="{ none: isShow }">
      <div class="got-it" @click="understand">
        <img src="~@/assets/my/cancel_button@2x.png" alt="" />
      </div>
      <div class="hint">
        <img src="~@/assets/my/sharetips_img@2x.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import tools from "@/common/js/tools.js";
import { Dialog } from "vant";
import { getShareShop } from "@/api/shop.js";
export default {
  name: "",
  props: {
    headImgUrl: require("@/assets/common/personal_centerhead_portrait.png"),
    showSharePage: {
      default: true,
    },
  },
  data() {
    return {
      show: true,
      isShow: false,
      // showShare: this.showSharePage,
      shareInfo: {
        code_img: "",
        id: 0,
        shop_code: "",
        shop_logo: "",
        shop_name: "",
        shop_share_img: "",
        share_url: "",
      },
    };
  },
  mounted() {
    this.isWx = tools.isWx();
    if (this.isWx) {
      tools.wxInitialize([
        "onMenuShareAppMessage",
        "updateAppMessageShareData",
        "updateTimelineShareData",
      ]);
    }
    if (this.showSharePage) {
      this.getShareInfo();
    }
  },
  methods: {
    understand() {
      this.isShow = false;
    },
    shareFriend() {
      console.log(tools.isWx());
      if (tools.isWx()) {
        this.isShow = true;
      } else {
        Dialog.alert({
          title: "提示",
          message: "请到微信分享给你最爱的朋友吧~~",
          theme: "round-button",
        }).then(() => {
          // on close
        });
      }
    },
    preserve() {
      if (tools.isWx()) {
        Dialog.alert({
          title: "提示",
          message: "长按图片保存到相册~~",
          theme: "round-button",
        }).then(() => {
          // on close
        });
      } else {
        Dialog.alert({
          title: "提示",
          message: "请到微信分享给你最爱的朋友吧~~",
          theme: "round-button",
        }).then(() => {
          // on close
        });
      }
    },

    shutDown() {
      this.$emit("setShareCode");
    },
    async getShareInfo() {
      const res = await getShareShop();
      if (res.code * 1 === 1) {
        this.shareInfo = res.data;
        console.log(res);
        this.setWxShare();
      }
    },
    setWxShare() {
      if (!this.isWx) {
        return false;
      }
      let _that = this;
      wx.ready(function () {
        // 需在用户可能点击分享按钮前就先调用
        console.log({
          title: _that.shareInfo.shop_name, // 分享标题
          desc: "店铺注册", // 分享描述
          link: _that.shareInfo.share_url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: _that.shareInfo.shop_logo, // 分享图标
        });
        wx.updateAppMessageShareData({
          title: _that.shareInfo.shop_name, // 分享标题
          desc: "店铺注册", // 分享描述
          link: _that.shareInfo.share_url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: _that.shareInfo.shop_logo, // 分享图标
          success: function () {
            // 设置成功
          },
        });
        wx.updateTimelineShareData({
          title: _that.shareInfo.shop_name, // 分享标题
          link: _that.shareInfo.share_url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: _that.shareInfo.shop_logo, // 分享图标
          success: function () {
            // 设置成功
          },
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.van-popup {
  width: 336px;
  //height: 449px;
  background-color: transparent;
  overflow-y: visible;
}
.block {
  position: relative;
  height: 100%;
  width: 100%;
  .close-icon {
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    color: #feffff;
  }
}
.code-img-box {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  overflow: hidden;
  .code-img {
    display: block;
    width: 100%;
    height: 100%;
  }
}
.van-overlay {
  z-index: 2000;
}
.popup-tier {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.8);
  .got-it {
    position: absolute;
    bottom: 226px;
    right: 96px;
    width: 186px;
    img {
      display: block;
      width: 100%;
    }
  }
  .hint {
    position: absolute;
    right: 0;
    top: 0;
    width: 270px;
    img {
      display: block;
      width: 100%;
    }
  }
}
.none {
  display: block;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

// .block {
//   position: relative;
//   width: 336px;
//   height: 430px;
//   background-color: #fff;
//   border-radius: 16px;
//   background: url("~@/assets/common/qr-code-background@2x.png") no-repeat
//     center/100%;

//   .shutDown-icon {
//     position: absolute;
//     top: -42px;
//     left: 50%;
//     transform: translateX(-50%);
//     color: #cccccc;
//   }

//   .user-info {
//     position: absolute;
//     top: 102px;
//     left: 28px;
//     display: flex;
//     align-items: center;

//     .head-portrait {
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       width: 62px;
//       height: 62px;
//       margin-right: 10px;
//       border-radius: 50%;
//       overflow: hidden;
//       .head-img {
//         width: 100%;
//         display: block;
//       }
//     }

//     .info {
//       display: flex;
//       flex-direction: column;

//       .user-name {
//         font-weight: 600;
//       }

//       .user-id {
//         font-size: 12px;
//         color: #6f6f6f;
//       }
//     }
//   }

//   .two-code {
//     position: absolute;
//     bottom: 58px;
//     left: 50%;
//     transform: translateX(-50%);
//     width: 150px;
//     height: 150px;
//     img {
//       width: 100%;
//       display: block;
//     }
//   }
// }

.share-path {
  position: absolute;
  bottom: -86px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-around;
  width: 336px;

  .share-layout {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 40px;
      margin-bottom: 10px;
    }

    span {
      color: #fff;
      font-size: 14px;
    }
  }
}
</style>
