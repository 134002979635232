<template>
  <van-row class="my-order">
    <div v-show="false">
      <mini-audio ref="sysAudio" :audio-source="audioUrl"></mini-audio>
<!--      <audio id="bgMusic" v-if="isPlay">-->
<!--        <source  :src="audioUrl" type="audio/mp3">-->
<!--      </audio>-->
    </div>
    <van-col span="24">
      <div class="my-wallet">
        <div span="15" class="title-list" @click="testAddYp">
          <div class="title" @click.stop="testAddYp()">我的钱包</div>
          <div class="title-img" v-if="audioOk">
            <img src="~@/assets/my/audio-ok.png" class="audio-img" />
          </div>
          <div class="title-img-on" v-else>
            <img src="~@/assets/my/audio-no.png" class="audio-img" />
            <div class="img-text">
              语音播报
            </div>
          </div>
        </div>
        <div span="9" class="look-wallet" @click="goTuUrl(1)">
          <span>查看我的钱包</span>
          <van-icon name="arrow" size="16" />
        </div>
      </div>
      <!-- 第二行 -->
      <div class="wallet-layout">
        <div class="wallet-layout-content" @click="goTuUrl(1)">
          <img src="~@/assets/my/the-balance-icon.png" class="wallet-img" />
          <div class="wallet-text">
            <span>{{ fourNum(userInfo.money) || 0 }}</span>
            <div>余额</div>
          </div>
        </div>
        <div class="partition"></div>
        <div class="wallet-layout-content" @click="goTuUrl(2)">
          <img src="~@/assets/my/the-money-icon.png" class="wallet-img" />
          <div class="wallet-text">
            <span>{{ fourNum(userInfo.all_earnings) || 0 }}</span>
            <div>兑换金额</div>
          </div>
        </div>
      </div>
      <div class="shop-money" v-if="isShow" @click="goTuUrl(3)">
        <img src="~@/assets/my/shop_bg@2x.png" alt="" />
        <div class="money">
          <span class="text">店铺资金(元）：</span>
          <span class="number">
            ￥{{ fourNum(shopInfo.shop_money) || 0 }}
          </span>
        </div>
      </div>
    </van-col>
  </van-row>
</template>

<script>
import tools from "@/common/js/tools.js";
import socket from "@/common/js/socket";
import {Toast} from "vant";
import store from "@/store";

export default {
  name: "user-wallet",

  props: {
    userInfo: {
      default: {
        level_id: 0,
      },
    },
    shopInfo: {},
  },
  data() {
    return {
      audio:null,
      audioList:[],
      isPlay:false,
      audioOk:false,
      audioUrl:'http://demo.jhcs888.com/code/mp3/20230507/38bcdf927ce9475b3f2249fd4d25d845.mp3'
    };
  },
  computed: {
    isShow() {
      if (this.userInfo.level_id === 5) {
        return true;
      }
      return false;
    },
  },
  mounted() {

    let myTimeDisplay = setInterval(() => {
      this.getMySocketInfo();
    }, 1500);
    socket.on("userInformOrder", (ret) => {
      if (ret.code * 1 === 1) {
        // Toast.fail(ret.msg);
        console.log(ret.data)
        if(ret.data.url){
          // Toast.fail(ret.data.url);
          this.audioList.push(ret.data.url)
          // this.addYy()
          this.playAudio()
        }
      }
    });
  },
  methods: {
    testAddYp(){
      if(this.audioOk){
        // return
      }
      this.audioOk=true
        let arr=[
          'http://demo.jhcs888.com/code/mp3/20230421/ef6551176b9e4d07f1f853431cd98e51.mp3',
          // 'http://demo.jhcs888.com/code/mp3/20230507/38bcdf927ce9475b3f2249fd4d25d845.mp3',
          // 'http://demo.jhcs888.com/code/mp3/20230507/f0d6f24f9c6fb3b348e2dfbbdc70a0a2.mp3',
          // 'http://demo.jhcs888.com/code/mp3/20230507/5d130e951164ab1e842887315591435d.mp3',
          // 'http://demo.jhcs888.com/code/mp3/20230507/2fa95337195d0e120ef8f27685478eae.mp3',
        ];
      arr.forEach((url)=>{
        this.audioList.push(url)
      })
      this.playAudio()
    },
    addYy(){
      if(this.audioOk){
        return
      }
      this.audioOk=true

     let url = 'http://demoapi.jhcs888.com/code/mp3/20230421/ef6551176b9e4d07f1f853431cd98e51.mp3'
      // this.audio.play()
      // this.$refs.sysAudio.play()
      // this.$refs.sysAudio.addEventListener('ended',this.limitPlay)
      this.audioList.push(url)
      this.playAudio()
    },
    getMySocketInfo(){
      socket.emit('userInformOrder', {m_id:store.state.m_id,'token':store.state.token});
    },
    playAudio(){


      if(this.isPlay){
        // Toast.fail('正在播报');
        // let durationNum=this.$refs.sysAudio.duration
        // console.log('durationNum:'+durationNum)
        // console.log('durationTime:'+(durationNum*1000+1000))
        // setTimeout(()=>{
        //   this.limitPlay()
        // },durationNum*1000+1000)
      }else {
        this.isPlay=true

        setTimeout(()=>{
          this.audioUrl =  this.audioList[0]
          // let audio =  document.getElementById("bgMusic");
          // audio.src =this.audioUrl

          console.log('this.audioUrl:'+this.audioUrl)
          setTimeout(()=>{
            // Toast.fail('开始播报');
            // Toast.fail(this.audioUrl);
            let durationNum=this.$refs.sysAudio.duration
            // console.log( `duration: ${audio.duration}`)
            // let durationNum=audio.duration
            console.log('durationNum:'+durationNum)
            console.log('durationTime:'+(durationNum*1000+1000))
            if(durationNum){
              // audio.currentTime = 0;
              // audio.play()
              this.$refs.sysAudio.pause()
              this.$refs.sysAudio.setSeek(0)
              this.$refs.sysAudio.play()
              setTimeout(()=>{
                // Toast.fail('播放回调');
                this.limitPlay()
              },durationNum*1000+1000)
            }else {
              this.isPlay=false
              this.limitPlay()
            }
          },500)
        },50)
      }
    },
    limitPlay(){
      // Toast.fail('播报完成'+this.audioList.length);
      this.isPlay=false
      //消息队列中第一条消息播放完成后,判断消息队列总有几条
      if(this.audioList.length<=1){
        this.audioList.shift()
        // this.audio.pause()
        // this.audioList.shift()
      }else{
        //有多数据时,第一条播放完删掉第一条消息数据后,直接显示播放下一条消息
        this.audioList.shift()
        this.playAudio()
      }
    },
    fourNum(num) {
      return tools.fourFloat(num);
    },
    goTuUrl(type) {
      switch (type) {
        case 1:
          this.$router.push({
            name: "remaining-sum",
            query: { moneyType: 1, levelId: this.userInfo.level_id },
          });
          break;
        case 2:
          // this.$router.push({
          //   name: "remaining-sum",
          //   query: { moneyType: 2, levelId: this.userInfo.level_id },
          // });
          break;
        case 3:
          // this.$router.push({
          //   name: "remaining-sum",
          //   query: { moneyType: 3, levelId: this.userInfo.level_id },
          // });
          break;
        default:
      }
    },
  },
};
</script>

<style lang="less" scoped>
.my-order {
  background-color: #fff;
  border-radius: 15px;
  padding: 14px 10px 14px 13px;
  margin-top: 13px;
}
.my-wallet {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 17px;
}
.title-list{
  display: flex;
  justify-content: flex-start;
  .title {
    font-weight: 500;
    height: 24px;
    line-height: 24px;
    margin-right: 12px;
  }
  .title-img{
    .audio-img{
      width: 24px;
      height: 24px;
    }
  }

}
.title-img-on{
  border-radius: 13px !important;
  padding: 4px 8px !important;
  display: flex;
  justify-content: flex-start;
  background: rgba(228,228,228,0.2);
}
.title-list .title-img-on .audio-img{
  width: 16px;
  height: 16px;
  margin-right: 2px;
}
.title-list .title-img-on .img-text{
  color: #6F6F6F;
  height: 16px;
  line-height:  18px;
  font-size: 12px;
  font-weight: 500;
}



.look-wallet {
  display: flex;
  justify-content: end;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  color: #bbbbbb;
  margin-right: 2px;
}

.wallet-layout {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .partition {
    height: 26px;
    width: 1px;
    background-color: #aaaaaa;
    opacity: 0.4;
  }

  padding: 16px 18px 10px 18px;

  .wallet-layout-content {
    display: flex;
    justify-content: start;
    align-items: center;

    .wallet-img {
      width: 42px;
      height: 42px;
    }

    .wallet-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 0 0 12px;
    }
  }
}

.wallet-text span {
  font-size: 22px;
}

.wallet-text div {
  font-size: 16px;
  color: #6f6f6f;
}
.shop-money {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 20px;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
  .money {
    display: flex;
    align-items: center;
    position: absolute;
    left: 72px;
    top: 50%;
    transform: translateY(-50%);
  }
  .text {
    color: #6f6f6f;
    font-size: 16;
  }
  .number {
    font-size: 21px;
  }
}
</style>
