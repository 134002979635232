<template>
  <!-- 分享弹出层 -->
  <van-popup
    v-model="isShare"
    :close-on-click-overlay="false"
    @click-overlay="clickPopup"
    v-if="isShare"
  >
    <div class="share">
      <div class="close-btn" @click="clickPopup">
        <van-icon name="cross" size="16" color="#fff" />
      </div>
      <div class="share-img-box">
        <img :src="imgUrl" />
      </div>
      <div class="btn-layout">
        <!-- <div class="btn-operation" v-if="false">
          <div class="btn-img-box">
            <img src="~@/assets/common/share-wechat-distribution.png" alt="" />
          </div>
          <span>发送好友</span>
        </div> -->
        <div class="btn-operation" @click="savePhoto">
          <div class="btn-img-box">
            <img src="~@/assets/common/share-save-album.png" alt="" />
          </div>
          <span>保存相册</span>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { getShopPay } from "@/api/shop";
import { Dialog } from "vant";
import tools from "@/common/js/tools";
export default {
  name: "share",
  props: {
    goodsClass: {
      type: Object,
    },
    isShare: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imgUrl: "",
      isWx: false,
      shareInfo: {
        code_img: "",
        head_img: "",
        id: 0,
        invite_code: "",
        nickname: "",
        share_img: "",
      },
    };
  },
  watch: {
    isShare(newVal, oldVal) {
      if (newVal) {
        this.getShopPay();
      }
    },
  },
  mounted() {
    this.isWx = tools.isWx();
    if (this.isWx) {
      tools.wxInitialize([
        "onMenuShareAppMessage",
        "updateAppMessageShareData",
        "updateTimelineShareData",
      ]);
    }
  },
  methods: {
    setWxShare() {
      if (!this.isWx) {
        return false;
      }
      let _that = this;
      wx.ready(function () {
        // 需在用户可能点击分享按钮前就先调用
        let myUrl = window.location.href;
        if (myUrl.indexOf("?") <0) {
          myUrl = myUrl+'?';
        }
        if (myUrl.indexOf("m_id") !== -1) {
          myUrl = myUrl.substr(0, myUrl.indexOf("m_id"));
        }
        myUrl = myUrl + "&invite_code=" + _that.shareInfo.invite_code;
        console.log(myUrl);
        wx.updateAppMessageShareData({
          title: _that.shareInfo.nickname, // 分享标题
          desc: "分享商品啦", // 分享描述
          link: myUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: _that.shareInfo.code_img, // 分享图标
          success: function () {
            // 设置成功
          },
        });
        wx.updateTimelineShareData({
          title: _that.shareInfo.nickname, // 分享标题
          link: myUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: _that.shareInfo.code_img, // 分享图标
          success: function () {
            // 设置成功
          },
        });
      });
    },
    savePhoto() {
      if (tools.isWx()) {
        Dialog.alert({
          title: "提示",
          message: "长按图片保存到相册~~",
          theme: "round-button",
        }).then(() => {
          // on close
        });
      } else {
        // Dialog.alert({
        //   title: "提示",
        //   message: "请到微信分享给你最爱的朋友吧~~",
        //   theme: "round-button",
        // }).then(() => {
        //   // on close
        // });
        let a = document.createElement("a");
        a.href = this.imgUrl;
        a.click();
      }
    },
    clickPopup() {
      this.$emit("colseGather");
    },
    async getShopPay() {
      const res = await getShopPay();
      this.imgUrl = res.data.pay_img;
      // console.log(res);
    },
  },
};
</script>

<style lang="less" scoped>
.van-popup {
  background-color: transparent;
  overflow: visible;
}
.van-popup--center {
  top: 55%;
}
.share {
  position: relative;
  width: 100%;
  height: 100%;
  .close-btn {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 31px;
    height: 31px;
    border: 1px solid #feffff;
    border-radius: 50%;
    opacity: 1;
  }
  .share-img-box {
    width: 336px;
    height: 508px;
    img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 16px;
    }
  }
}
.btn-layout {
  display: flex;
  justify-content: center;
  padding: 20px 58px;
  .btn-operation {
    display: flex;
    flex-direction: column;
    align-items: center;
    .btn-img-box {
      width: 40px;
      height: 40px;
      img {
        display: block;
        width: 100%;
      }
    }
    span {
      margin-top: 6px;
      color: #fff;
    }
  }
}
</style>
