<template>
  <div>
    <div class="head-back">
      <img src="~@/assets/my/my-page-background@2x.png" class="head-back-img" />
    </div>
    <NavBar
      class="nav-bar"
      :class="{ 'nav-color': isSwitchover }"
      :iconColor="navIconColor"
      :iconName="'cross'"
    >
      <div slot="title">个人中心</div>
    </NavBar>
    <div class="layout">
      <!-- 个人信息 -->
      <UserInfo :userInfo="userInfo" :shopInfo="shopInfo"></UserInfo>
      <!-- 钱包 -->
      <UserWallet :userInfo="userInfo" :shopInfo="shopInfo"></UserWallet>
      <!-- 商品订单 -->
      <ShopOrder :orderType="2" v-if="userInfo.level_id * 1 === 5"></ShopOrder>
      <!-- 发展收益 -->
      <EvolutionEarnings :userInfo="userInfo"></EvolutionEarnings>
      <!-- 我的订单 -->
      <MyOrder :userInfo="userInfo"></MyOrder>
      <!-- 更多功能 -->
      <MoreOption :userInfo="userInfo"></MoreOption>
    </div>
    <!-- <Loading :show="loadingShow"></Loading> -->
  </div>
</template>

<script>
import UserInfo from "@/pages/my/my/moudles/user-info/user-info.vue";
import UserWallet from "@/pages/my/my/moudles/user-wallet/user-wallet.vue";
import ShopOrder from "@/pages/my/my/moudles/shop-order/shop-order.vue";
import EvolutionEarnings from "@/pages/my/my/moudles/evolution-earnings/evolution-earnings.vue";
import MyOrder from "@/pages/my/my/moudles/my-order/my-order.vue";
import MoreOption from "@/pages/my/my/moudles/more-option/more-option.vue";
import NavBar from "@/layout/nav-bar/nav-bar";
// import Loading from "@/components/loading";
/***
 * 接口
 */
import { getMemberInfo } from "@/api/member";
import { getMyShop } from "@/api/shop";

import socket from "@/common/js/socket";
import store from '@/store'
import { Toast } from "vant";

export default {
  components: {
    NavBar,
    UserInfo,
    UserWallet,
    EvolutionEarnings,
    MyOrder,
    MoreOption,
    ShopOrder,
    // Loading,
  },
  data() {
    return {
      loadingShow: false,
      isSwitchover: false,
      transparent: "transparent",
      textColor: "#fff",
      userInfo: {
        shop_id:0
      },
      navIconColor: "",
      shopInfo: {},
    };
  },
  async mounted() {
    this.loadingShow = true;
    this.getMyShop();
    const res = await getMemberInfo();
    console.log(res);
    if (res.code * 1 === 1) {
      this.userInfo = res.data;
    }
    addEventListener("scroll", this.scrollToTop);
    let that = this;
    const timer = setTimeout(() => {
      if (that.isOverlay) {
        that.isOverlay = false;
        clearTimeout(timer);
      }
    }, 5);

    // this.getMySocketInfo()
    // clearInterval(myTimeDisplay);
    let myTimeDisplay = setInterval(() => {
      this.getMySocketInfo();
    }, 1000);
    socket.on("memberInfo", (ret) => {
      if (ret.code * 1 === 1) {
        this.userInfo.money = Number(ret.data.money)
        this.userInfo.consume_money = Number(ret.data.consume_money)
        this.shopInfo.shop_money = Number(ret.data.shop_money)
      }
    });
  },
  methods: {
    getMySocketInfo(){
      socket.emit('memberInfo', {m_id:store.state.m_id});
    },

    scrollToTop() {
      const scrollTop = window.pageYOffset;
      if (scrollTop >= 28) {
        this.isSwitchover = true;
        this.navIconColor = "#9B9B9B";
      } else if (scrollTop < 28) {
        this.isSwitchover = false;
        this.navIconColor = "#fff";
      }
    },
    async getMyShop() {
      let ret = await getMyShop();
      if (ret.code * 1 === 1) {
        this.shopInfo = ret.data;
        console.log(ret.data);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
}
.nav-bar {
  color: #fff;
  transition: all 0.1s;
}
.nav-color {
  color: #000;
  background-color: #fff;
  border-bottom: 1px solid rgb(246, 246, 246);
}
.layout {
  padding: 50px 13px 76px 13px;
}
.head-back-img {
  width: 100%;
}
.head-back {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: -1;
}
.head-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 29px;
  color: #ffffff;
  font-size: 21px;
}
.head-left-img {
  width: 16px;
}
.head-right-img {
  width: 19px;
}
</style>
